<template>
  <div class="run-list-box">
    <div class="nav-list">
      <div :class="status===item.status?'s-b':'n-b'" @click="selectS(item.status)"
      v-for="(item,i) in navList" :key="i">{{item.name}}</div>
    </div>

    <div class="info-list" v-if="list.length>0">
      <div class="info-list-box" v-for="(item,i) in list" :key="i" @click.stop="toDet(item.id)">
        <div class="info-1">
          <img src="@/assets/seize-img/time-icon.png" alt="">
          <div class="">
            <span class="c-F87A08 fs-14 fw-600">{{item.time_str}}</span>
            <span class="c-333 fs-14 f-w-600">内送达</span>
          </div>
          <div class="info-1-money"><span class="fs-12">￥</span><span class="fs-22 fw-600">{{item.postage}}</span></div>
        </div>

        <div class="info-2">
          <div class="info-2-left">
            <img src="@/assets/seize-img/qu-icon.png" alt="">
            <div class="">{{item.distance*1>1000?(item.distance*0.001).toFixed(2):item.distance}}</div>
            <div class="">{{item.distance*1>1000?'km':'m'}}</div>
            <div class="f-g-div"></div>
            <img src="@/assets/seize-img/song-icon.png" alt="">
            <div class="">{{item.aadistance*1>1000?(item.aadistance*0.001).toFixed(2):item.aadistance}}</div>
            <div class="">{{item.aadistance*1>1000?'km':'m'}}</div>
          </div>
          <div class="info-2-right">
           <div class="fs-18 c-333 f-w-600">{{item.shop_name}}</div>
           <div class="fs-12 c-666">{{item.city_name}}</div>
           <div class="fs-18 c-333 f-w-600 mt-20">{{item.shop_name}}</div>
           <div class="fs-12 c-666">{{item.city_names}}</div>
          </div>
        </div>

        <div v-if="status === 1" class="infp-3 fs-16 fw-600 mt-20" @click.stop="runRob(item.id)">
          <span>抢单</span>
        </div>
        <div v-else-if="status === 2" class="infp-3 fs-16 fw-600 mt-20" @click.stop="getCode(item)">
          <span>确认取货</span>
        </div>
        <div v-else class="infp-3 fs-16 fw-600 mt-20" @click.stop="runService(item.id)">
          <span>确认送达</span>
        </div>
      </div>
    </div>

    <div class="blank-box" v-else>暂无数据</div>


    <div class="pop-box" v-show="show" @click.stop="closeQrCode()">
      <div class="pop-info">
        <div class="fs-18">取货码</div>
        <div class="codeimg" ref="qrCodeDiv"></div>
        <div class="fs-14">
          订单编号：{{order_sn}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {runOrderList,runRob,runService} from '@/api/runOrder.js';

  import { Toast,Dialog,Overlay    } from 'vant';

  import QRcode from 'qrcodejs2'

  export default {
    data(){
      return{
        show: false,
        lon: '106.61641',
        lat: '29.65489',
        status:1,
        navList:[{name:'待接单',status:1,},{name:'待取货',status:2,},{name:'待送达',status:3,},],
        order_sn:'',
        list:[],
      }
    },
    mounted() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position=>{
          this.lon = position.coords.longitude;
          this.lat = position.coords.latitude;
        });
      }
      this.getList()
    },
    methods:{
      getList(){
        runOrderList({
          lon:this.lon,
          lat:this.lat,
          status:this.status,
        }).then((res)=>{
          if(res.code === 1){
            this.list = res.data
          }
        })
      },
      selectS(status){
        if(this.status!=status){
          this.status = status;
          this.getList()
        }
      },
      // 抢单
      runRob(id){
        Dialog.confirm({
          title: '',
          message: '是否接取此订单',
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(() => {
          runRob({order_id:id}).then((res)=>{
            if(res.code === 1){
              Toast.success(res.msg);
              this.getList()
            }else{
              Toast.fail(res.msg);
            }
          })
        })
        .catch(() => {
          // on cancel
        });
      },
      // 送达
      runService(id){
        Dialog.confirm({
          title: '',
          message: '是否确认送达',
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(() => {
          runService({order_id:id}).then((res)=>{
            if(res.code === 1){
              Toast.success(res.msg);
              this.getList()
            }else{
              Toast.fail(res.msg);
            }
          })
        })
        .catch(() => {
          // on cancel
        });
      },

      // 确认缺货
      getCode(item){
        this.order_sn = item.order_sn + ''

        let qrcode = new QRcode(this.$refs.qrCodeDiv,{
          text:this.order_sn,
          width:180,
          height:180,
          colorDark:'#333333',
          colorLight:'#ffffff',
          correctLevel:QRcode.CorrectLevel.H,
        })

        this.show = true
      },
      closeQrCode(){
        this.show = false
        this.$refs.qrCodeDiv.innerHTML = ''
      },

      toDet(id){
        this.$router.push({path:'run-det',query:{order_id:id,lon:this.lon,lat:this.lat}})
      },
    }
  }
</script>

<style scoped lang="less">
  .pop-box{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    .pop-info{
      width: 260px;
      height: 305px;
      background: #FFFFFF;
      border-radius: 10px;
      box-sizing: border-box;
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      div{
        text-align: center;
      }
      .codeimg{
        margin: 16px auto;
      }
    }
  }


  .run-list-box{
    width: 100%;
    height: 100%;



    .blank-box{
      font-size: 16px;
      text-align: center;
      box-sizing: border-box;
      padding-top: 100px;
    }
    .info-list{
      box-sizing: border-box;
      padding: 12px;
      .info-list-box{
        margin-bottom: 10px;
        width:100%;
        min-height: 100px;
        background: #FFFFFF;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 16px;
        .mt-20{
          margin-top: 20px;
        }
        .c-F87A08{
          color: #F87A08;
        }
        .c-333{
          color: #333;
        }
        .c-666{
          color: #666;
        }
        .fs-12{
          font-size: 12px
        }
        .fs-14{
          font-size: 14px
        }
        .fs-18{
          font-size: 18px
        }
        .fs-22{
          font-size: 22px
        }
        .fw-600{
          font-weight: 600;
        }
        .info-1{
          display: flex;
          align-items: center;
          position: relative;
          img{
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }
          .info-1-money{
            position: absolute;
            right: 0;
            top: 0;
            span{
              color: #ED301D;
            }
          }
        }
        .info-2{
          margin-top: 16px;
          width: 100%;
          display: flex;
          .info-2-left{
            width: 40px;
            min-height: 100px;
            background: #F6F6F6;
            border-radius: 100px;
            margin-right: 12px;
            box-sizing: border-box;
            padding: 8px 0;
            text-align: center;
            display: flex;
            flex-direction: column;
            .f-g-div{
              flex: 1;
              width: 1px;
              margin: 3px auto 5px auto;
              background-color: #ccc;
            }
            img{
              margin: auto;
              display: block;
              width: 20px;
              height: 20px;
            }
          }
          .info-2-right{
            box-sizing: border-box;
            padding: 6px 0;
            flex: 1;
            min-height: 100px;
          }
        }
        .infp-3{
          width: 100%;
          height: 40px;
          background: linear-gradient(236deg, #FC5656 0%, #F70606 100%);
          border-radius: 100px;
          text-align: center;
          line-height: 40px;
          color: #fff;
        }
      }
    }

    .nav-list{
      width: 100%;
      height: 44px;
      background: #fff;
      display: flex;
      div{
        width: 33.3%;
        height: 100%;
        text-align: center;
        line-height: 44px;
        font-size: 16px;
      }
      .s-b{
        color: #333;
        font-weight: 600;
      }
      .n-b{
        color: #999;
        font-weight: 400;
      }
    }
  }
</style>
