<template>
  <div class="run-order-box">

    <div class="info-list" v-if="list.length>0">
      <div class="info-list-box" v-for="(item,i) in list" :key="i" @click.stop="toDet(item.id)">
        <div class="info-1">
          <div class="info-1-left">
            <img src="@/assets/seize-img/time-icon.png" alt="">
            <div class="info-time fs-14 fw-600" v-html="item.time_str"></div>
          </div>

          <div class="info-1-code fs-12 c-999">订单编号：{{item.order_sn}}</div>
          <div class="info-1-money">
            <div class="fs-12 c-999">已完成</div>
            <div class=""><span class="fs-12">￥</span><span class="fs-22 fw-600">{{item.postage}}</span></div>
          </div>

        </div>

        <div class="info-2">
          <div class="info-2-left">
            <img src="@/assets/seize-img/qu-icon.png" alt="">
            <div class="">{{item.distance*1>1000?(item.distance*0.001).toFixed(2):item.distance}}</div>
            <!-- <div class="">{{item.distance*1>1000?'km':'m'}}</div> -->
            <div class="f-g-div"></div>
            <img src="@/assets/seize-img/song-icon.png" alt="">
            <div class="">{{item.aadistance*1>1000?(item.aadistance*0.001).toFixed(2):item.aadistance}}</div>
            <!-- <div class="">{{item.aadistance*1>1000?'km':'m'}}</div> -->
          </div>
          <div class="info-2-right">
           <div class="fs-18 c-333 f-w-600">{{item.shop_name}}</div>
           <div class="fs-12 c-666">{{item.city_name}}</div>
           <div class="fs-18 c-333 f-w-600 mt-20">{{item.addre}}</div>
           <div class="fs-12 c-666">{{item.address}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="blank-box" v-else>暂无数据</div>
  </div>
</template>

<script>
  import {runServiceOrder} from '@/api/runOrder.js';


  export default {
    data(){
      return{
        lon: '106.61641',
        lat: '29.65489',

        list:[],
      }
    },
    mounted() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position=>{
          this.lon = position.coords.longitude;
          this.lat = position.coords.latitude;
        });
      }
      this.getList()
    },
    methods:{
      getList(){
        runServiceOrder().then((res)=>{
          if(res.code === 1){
            this.list = res.data.list
          }
        })
      },


      toDet(id){
        this.$router.push({path:'order-det',query:{order_id:id,lon:this.lon,lat:this.lat}})
      },
    }
  }
</script>

<style scoped lang="less">
  .run-order-box{
    width: 100%;
    height: 100%;

    .mt-20{
      margin-top: 20px;
    }
    .c-F87A08{
      color: #F87A08;
    }
    .c-333{
      color: #333;
    }
    .c-666{
      color: #666;
    }
    .c-999{
      color: #999;
    }
    .fs-12{
      font-size: 12px
    }
    .fs-14{
      font-size: 14px
    }
    .fs-18{
      font-size: 18px
    }
    .fs-22{
      font-size: 22px
    }
    .fw-600{
      font-weight: 600;
    }

    .blank-box{
      font-size: 16px;
      text-align: center;
      box-sizing: border-box;
      padding-top: 100px;
    }
    .info-list{
      box-sizing: border-box;
      padding: 12px;
      .info-list-box{
        margin-bottom: 10px;
        width:100%;
        min-height: 100px;
        background: #FFFFFF;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 16px;

        .info-1{
          display: flex;
          align-items: center;
          position: relative;
          .info-1-left{
            display: flex;
            align-items: center;
            .info-time{
              ::v-deep p{
                display: inline !important;
              }
            }
            img{
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
          }
          .info-1-code{
            position: absolute;
            left: 0;
            top: 25px;
          }
          .info-1-money{
            position: absolute;
            right: 0;
            top: 0;
            div{
              text-align: right;
              margin-bottom: 3px;
            }
            span{
              color: #ED301D;
            }
          }
        }
        .info-2{
          margin-top: 40px;
          border-top: 1px solid #EEEEEE;
          width: 100%;
          display: flex;
          padding-top: 10px;
          .info-2-left{
            width: 40px;
            min-height: 100px;
            // background: #F6F6F6;
            border-radius: 100px;
            margin-right: 12px;
            box-sizing: border-box;
            padding: 8px 0;
            text-align: center;
            display: flex;
            flex-direction: column;
            .f-g-div{
              flex: 1;
              width: 1px;
              margin: 10px auto 10px auto;
              background-color: #ccc;
            }
            img{
              margin: auto;
              display: block;
              width: 20px;
              height: 20px;
            }
          }
          .info-2-right{
            box-sizing: border-box;
            padding: 6px 0;
            flex: 1;
            min-height: 100px;
          }
        }
      }
    }
  }
</style>
