<template>
  <div class="run-index-box">
    <div class="r-box" @click="toHome()">
      <img src="@/assets/seize-img/info-left.png" alt="">
      <div class="">{{navType===1?'跑腿':'订单'}}</div>
    </div>
    <div class="run-info">
      <runList v-if="navType === 1"></runList>
      <runOrder  v-else></runOrder>
    </div>
    <div class="bottom-nav">
      <div class="div-box" @click="selectDom(1)">
        <img v-if="navType===1" src="@/assets/seize-img/s2.png" alt="">
        <img v-else  src="@/assets/seize-img/n2.png" alt="">
        <div :class="navType === 1?'s-box':'n-box'">任务</div>
      </div>
      <div class="div-box" @click="selectDom(2)">
        <img v-if="navType===2" src="@/assets/seize-img/s1.png" alt="">
        <img v-else src="@/assets/seize-img/n1.png" alt="">
        <div :class="navType === 2?'s-box':'n-box'">订单</div>
      </div>
    </div>
  </div>
</template>

<script>
  import runList from "./mod/run-list.vue"
  import runOrder from "./mod/run-order.vue"

  export default {
    components:{runList,runOrder},
    data(){
      return{
        navType:1,
      }
    },
    methods:{
      selectDom(type){
        if(this.navType !== type){
          this.navType = type
    
        }
      },
      toHome(){
        this.$router.push({path:'/home'})
      },
    },
  }
</script>

<style scoped lang="less">
  .run-index-box{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #F5F4F9;
    .r-box{
      width: 100%;
      height: 44px;
      background: #fff;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding-left: 13px;
      img{
        width: 30px;
        height: 30px;
      }
      div{
        font-size: 16px;
        margin-left: 10px;
        font-weight: 600;
      }
    }
    .run-info{
      flex: 1;
      overflow-y: scroll;
    }
    .bottom-nav{
      width: 100%;
      height: 49px;
      background: #FFFFFF;
      display: flex;
      justify-content: space-between;
      .div-box{
        width: 50%;
        height: 100%;
        text-align: center;
        img{
          width: 22px;
          height: 22px;
          margin: 6px 0 0 0;
        }
        div{
          font-size: 10px;
        }
      }
      .s-box{
        color: #ED301D;
      }
      .n-box{
        color: #666666;
      }
    }
  }
</style>
